html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

@font-face {
  font-family: "RhodiumLibre-Regular";
  src: local("RhodiumLibre-Regular"),
    url("./fonts/RhodiumLibre-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InriaSerif-Bold";
  src: local("InriaSerif-Bold"),
    url("./fonts/InriaSerif-Bold.ttf") format("truetype");
}
